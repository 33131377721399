import {
    Box,
    Text,
    VStack,
    Link,
    Center,
    Icon,
    useColorModeValue,
    Header,
} from '@chakra-ui/react';
import Navbar from '../navbar';
import Footer from '../components/footer';

const CircleIcon = (props) => (
    <Icon viewBox="0 0 200 200" {...props}>
        <path
            fill="currentColor"
            d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
        />
    </Icon>
)

export default function terms() {
    return (

        <Box>
            <Navbar />
            <TermsHeader />
            <Terms />
            <Footer />
        </Box>
    )
}

const TermsHeader = () => {
    return (
        /* Terms and Conditions - last updated... */
        <VStack
            bg={useColorModeValue('white', 'gray.800')}
        >
           
        </VStack>
    );
}

const Terms = () => {
    return (
	 
            <Box w="100%" align="center" px={{ base: 8, md: 16 }} pb={{ base: 20, md: 40 }}>
		
                <Box w="100%" align="center">
        		<iframe width="100%" height="19700px" scrolling="no" src="https://app-api.yintran.com/static/en_/TermsAndConditions.html" />
	 
		</Box>
		
	</Box>
	
    )
}
