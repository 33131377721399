import {
    Box,
    Text,
    VStack,
    Link,
    Button,
    Center, Icon, Divider, Stack,
    Popover,
    PopoverTrigger,
    useColorModeValue,
    PopoverContent,
    Flex,
} from '@chakra-ui/react';

import Navbar from '../navbar';
import Footer from '../components/footer';

export default function careers() {
    return (
        <Box>
            <Navbar />
            <Center paddingBottom={{ base: "0", md: "70" }}>
                <Careers />
            </Center>
            <Footer />
        </Box>
    )
}

const Careers = () => {
    return (
        <Box
            maxW="5xl"
            px={{ base: 2, md: 16 }} pb={{ base: 20, md: 40 }}>
            {/* Terms and Conditions - last updated... */}
            <VStack>
                <Text
                    pt={{ base: 40, md: 60 }}
                    pb="80px"
                    px="6"
                    fontSize={{ base: "36px", md: "64px" }}
                    align="center"
                    fontWeight="normal">
                    Grow with us
                </Text>
            </VStack>
            <Text
                py={{ base: "none", sm: "32px" }}
                px={6}
                pt="32px"
                pb="16px"
                fontSize={{ base: "xl", md: "2xl" }}
                align="center"
                >
                Come help build the future of digital payments with us. We value a down-to-earth, friendly, can-do attitude. If this sounds like you, we could be the company for you. Even if you don’t see the post you’re looking for, send us your details and we’ll get in touch if there is a role that might fit
            </Text>
            <Center paddingY="32px">
                <Link
                    px={{ base: "1", md: "6" }}
                    color="#088AFF"
                    fontSize={{ base: "md", md: "2xl" }}
                    fontWeight="medium"
                    href='mailto:careers@yintran.com'>
                    careers@yintran.com
                </Link>
            </Center>
            <Jobs />
        </Box>
    )
}

const Jobs = () => {
    return (
        <Box>
            {/* OPEN ROLES */}
            <VStack
                mt="48px"
                mb="80px">
                {/* <Center 
                fontWeight="light"
                pt={{base:6, md:12}} 
                pb={{base:3,md:10 }}
                px={{base:"none",md:6 }}
                fontSize={{base:"36px" , md:"64px"}}
                align="center"> 
                    Open roles
                    </Center>
                     */}


            </VStack>
            <JobListings />
        </Box>
    );
}

const JOB_ITEMS = [
    {
        label: 'Software Engineering',
        children: [
            {
                label: 'Flutter Developer (Mobile App Developer)',
                subLabel: 'Frontend/Fullstack',
                href: 'https://yintran.isnoting.com/mn9hpv9/flutter-developer-star-full-time-hk',
            },
            {
                label: 'Fullstack Web Developer',
                subLabel: 'Fullstack',
                href: 'https://yintran.isnoting.com/gvphee6/full-stack-web-developer-star-full-time-hk',
            },
            {
                label: 'Backend Developer',
                subLabel: 'Backend',
                href: 'https://yintran.isnoting.com/rkrhrrk/backend-developer-star-full-time-hk',
            },
            {
                label: 'Frontend Developer',
                subLabel: 'Frontend',
                href: '#',
            },
        ],
    },
    
    {
        label: 'Business Development',
        children: [
            {
                label: 'Business Development',
                subLabel: 'Business Development',
                href: 'https://yintran.isnoting.com/9n6hrry/business-development-star-full-time-hk',
            },
        ],
    },
    {
        label: 'Design',
        children: [

            {
                label: 'Assistant UIX Designer',
                subLabel: 'UIX Design',
                href: 'https://yintran.isnoting.com/o26hnn6/assistant-uix-designer-star-full-time-hk',
            },
            {
                label: 'Assistant Product Designer',
                subLabel: 'Product Design',
                href: 'https://yintran.isnoting.com/38nhzz1/assistant-product-manager-star-full-time-hk',
            },
        ],
    },
    {
        label: 'Legal',
        children: [
            {
                label: 'Compliance and AML Officer',
                subLabel: 'Compliance',
                href: '#',
            },
        ],
    },
    {
        label: 'Marketing',
        children: [
            {
                label: 'Marketing Officer',
                subLabel: 'Marketing',
                href: 'https://yintran.isnoting.com/vpohrrn/marketing-officer-star-full-time-hk',
            },
        ],
    },
    {
        label: 'Operations',
        children: [
            {
                label: 'Operations Officer',
                subLabel: 'Operations',
                href: 'https://yintran.isnoting.com/04khyyn/operations-officer-star-full-time-hk',
            },
        ],
    },
    {
        label: 'Product',
        children: [
            {
                label: 'Assistant Product Manager and Operations',
                subLabel: 'Product and Project Management',
                href: 'https://yintran.isnoting.com/38nhzz1/assistant-product-manager-star-full-time-hk',
            },
        ],
    },
];


const JobListings = () => {
    return (
        <Stack
            // px={{base:8, md:16}}  
            direction={'column'}
            spacing={20} //between job categories
            align='left'>
            {JOB_ITEMS.map((jobItem) => (
                <Box key={jobItem.label}>
                    <Link
                        p={2}
                        href={jobItem.href ?? '#'}
                        fontWeight={300}
                        // color={useColorModeValue('gray.900', 'gray.200')}
                        // _hover={{
                            // textDecoration: 'none',
                            // color: useColorModeValue('gray.800', 'white'),
                            // color: 'white',
                        // }}
                        fontSize={{base:'3xl',md:'4xl'}} //jobcategory label
                        
                        >
                        {jobItem.label}
                    </Link>

                    {jobItem.children && (
                        <Stack>
                            {jobItem.children.map((child) => (
                                <JobListingsSubList key={child.label} {...child} />
                            ))}
                        </Stack>
                    )}
                    <Divider pt={30}/>
                </Box>
            ))}
            <Text 
            fontSize={{ base: "lg", md: "2xl" }}
            >
                Don't see what you're looking for? Send us your details at
				<Link
                    // px={{ base: "1", md: "6" }}
                    color="#088AFF"
                    // fontSize={{ base: "md", md: "2xl" }}
                    fontWeight="medium"
                    href='mailto:careers@yintran.com'>
                     {' '}careers@yintran.com
                </Link>.		
				
				We’ll get in touch if there is a role or future opening that might fit
            </Text>
        </Stack>
    );
};

const JobListingsSubList = ({ label, href, subLabel }) => {
    return (
        <Link
            color='gray.500'
            // fontSize='md'
            href={href} isExternal
            role={'group'}
            display={'block'}
            p={2}
            rounded={'md'}>
            {/* subLabel text color */}
            <VStack
                direction={'column'}
                align={'left'}>
                <Box>
                    <Text
                        // transition={'all .3s ease'}
                        color={'blue.400'}
                        _groupHover={{ 
                        color: useColorModeValue('blue.300', 'blue.200'),}}
                        fontWeight={500}
                        opacity='100%'
                        fontSize={{base:'xl',md:'2xl'}}>
                        {label}
                    </Text>
                    <Text fontSize={{base:'sm',md:'md'}}>{subLabel}</Text>
                </Box>
                {/* desktop dropdown arrow color*/}
                <Flex
                    // transition={'all .3s ease'}
                    // transform={'translateX(-10px)'}
                    opacity={0}
                    _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
                    justify={'flex-end'}
                    align={'center'}
                    flex={1}
                >
                </Flex>

            </VStack>
        </Link>
    );
};

// 